import * as React from 'react';

import { Text, BlogImage, BlogLiterature } from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import signsOfLiteracy from '../../../images/educational-corner/blog14/Dijete-pokazuje-znakove-pismenosti.png';
import talkAboutEvent from '../../../images/educational-corner/blog14/Dijete-pripovijeda-o-dogadaju.png';
import showBookInterest from '../../../images/educational-corner/blog14/Djeca-pokazuju-zanimanje-za-knjige.png';

const PrerequisitesOfReadingAndWriting = () => {
  return (
    <PageNew
      title="KOKOLINGO - Predvještine čitanja i pisanja"
      description="Čitanje ima brojne definicije i za svakoga od nas znači nešto drugo.
      Svaki dan okruženi smo različitim tekstovima - u knjigama, na portalima i društvenim mrežama.
      Da bismo došli do razine da možemo brzo i točno čitati, ali i razumjeti sav taj sadržaj i pisati tolike poruke, 
      morali smo se veoma potruditi u prvih nekoliko razreda osnovne škole."
      headline="Predvještine čitanja i pisanja"
    >
      <Text as="p">
        Čitanje ima brojne definicije i za svakoga od nas znači nešto drugo.
        Nekima je čitanje veliko zadovoljstvo, a nekima velika muka. Svaki dan
        okruženi smo različitim tekstovima - u knjigama, na portalima i
        društvenim mrežama. Dnevno šaljemo na desetke ili stotine tekstualnih
        poruka. Da bismo došli do razine da možemo brzo i točno čitati, ali i
        razumjeti sav taj sadržaj i pisati tolike poruke, morali smo se veoma
        potruditi u prvih nekoliko razreda osnovne škole. Međutim, čak i prije
        polaska u školu djeca usvajaju predvještine koje će im kasnije omogućiti
        da postanu pismeni. Razvoj pismenosti možemo podijeliti u tri velike
        cjeline - izranjajuća pismenost, rana pismenost te čitanje i pisanje. U
        ovom tekstu usredotočit ćemo se na izranjajuću i ranu pismenost.
      </Text>
      <Text as="h2">Izranjajuća pismenost</Text>
      <Text as="p">
        U ovoj fazi razvoja dijete ulazi u rane interakcije s pisanim tekstom.
        To se najčešće događa <b>oko druge godine djetetova života.</b> Dijete
        pokazuje zanimanje za knjige i slikovnice, pretvara se da čita, okreće
        stranice, pokazuje prstom i imenuje slike. Sluša i ponavlja jednostavne
        tekstove iz slikovnica, priča i pjesmica. Nosi slikovnice i knjige,
        imenuje ih i bira što će mu odrasli čitati. Prepoznaje najčešće znakove
        koji ga okružuju, primjerice znak za BMW ili Konzum. Šara i crta po
        papiru u okomitom, vodoravnom i/ili kružnom smjeru.
      </Text>
      <BlogImage
        imgSrc={showBookInterest}
        imgAlt="Djeca pokazuju zanimanje za knjige"
        imgTitle="Zanimanje za knjige"
      />
      <Text as="h2">Rana pismenost</Text>
      <Text as="p">
        Rana pismenost odnosi se na preduvjete, odnosno na sposobnosti i
        vještine bitne za ovladavanje čitanjem koje dijete stječe rano u životu,
        prije nego što započne s formalnom podukom polaskom u osnovnu školu. Da
        bi dijete ovladalo tim vještinama mora imati uredne kognitivne
        sposobnosti, motoriku (grubu i finu), vizualnu i auditivnu percepciju te
        uredne jezično-govorne sposobnosti.
      </Text>
      <Text as="p">
        <b>Oko treće godine života</b> dijete će sve više vremena provoditi
        slušajući priče i pjesmice. Držat će knjige uspravno, prikladno će
        okretati stranice, zatvarat će knjige ili slikovnice i govoriti “Kraj!”
        ili “Gotovo!”. Pretvarat će se da čita poznati sadržaj, ponavljat će
        poznate izraze dok lista stranice i odgovarati na jednostavna pitanja o
        sadržaju priča. U pisanju će kopirati okomitu i vodoravnu liniju te
        krug.
      </Text>
      <Text as="p">
        <b>U četvrtoj godini života</b> dijete će početi razlikovati slike od
        riječi, pravilno će držati knjige i okretati stranice jednu po jednu. U
        toj dobi počinje razumijevati da čitamo slijeva nadesno, odozgo prema
        dolje, da se riječi sastoje od slova koja imaju svoje nazive te da
        moraju biti pisana u određenom redoslijedu da tvore riječi. U ovom
        razdoblju dijete shvaća da su riječi u tekstu odvojene razmacima,
        odnosno da su riječi “sve ono između bijelih praznina”. Dijete će početi
        prepoznavati najučestalija velika i mala tiskana slova, počet će
        prepoznavati i reproducirati rimu, raščlanjivati rečenicu na riječi i
        riječi na slogove te spajati slogove u riječi.
      </Text>
      <BlogImage
        imgSrc={signsOfLiteracy}
        imgAlt="Dijete pokazuje znakove rane pismenosti kod citanja knjiga"
        imgTitle="Znakovi rane pismenosti"
      />
      <Text as="h2">Sastavnice rane pismenosti</Text>
      <Text as="p">
        U ranu pismenost ubrajamo fonološku svjesnost, rječnik, pripovijedanje,
        imenovanje slova i svjesnost o tisku.
      </Text>
      <Text as="p">
        <ul>
          <li>
            FONOLOŠKA SVJESNOST
            <Text as="p">
              Fonološka svjesnost, uz imenovanje slova, jedan je od najvažnijih
              pokazatelja kasnije uspješnosti u čitanju. Očituje se u
              prepoznavanju riječi koje se rimuju, prebrojavanju slogova unutar
              riječi i odvajanju početka od kraja riječi. Vještinu
              prepoznavanja, izdvajanja i baratanja glasovima, odnosno fonemima
              nazivamo fonemskom svjesnošću. U određenoj dobi možemo očekivati
              da će se pojaviti jedna od sastavnica fonološke svjesnosti:
            </Text>
            <ul>
              <li>
                <b>prepoznavanje i proizvodnja rime</b>
              </li>
              <ul>
                <li>oko 3. i 4. godine</li>
              </ul>
              <li>
                <b>slogovna sinteza</b>, odnosno spajanje slogova u riječi,{' '}
                <b>i slogovna analiza</b>, odnosno razdvajanje riječi na slogove
              </li>
              <ul>
                <li>oko 4. i 5. godine</li>
              </ul>
              <li>
                <b>izdvajanje prvog i zadnjeg glasa u riječima</b>
              </li>
              <ul>
                <li>oko 5 i pol godina</li>
              </ul>
              <li>
                <b>fonemska sinteza</b>, odnosno spajanje glasova u riječi,{' '}
                <b>i fonemska analiza</b>, odnosno razdvajanje riječi na glasove
              </li>
              <ul>oko 6. godine</ul>
              <li>
                <b>shvaćanje veze slovo - glas</b>
              </li>
              <ul>
                <li>oko 6 i pol godina</li>
              </ul>
              <li>
                <b>manipulacija fonemima</b>, odnosno stvaranje novih riječi
                dodavanjem, oduzimanjem i premještanjem glasova
              </li>
              <ul>
                <li>oko 7. godine.</li>
              </ul>
            </ul>
          </li>
        </ul>
      </Text>
      <Text as="p">
        <ul>
          <li>
            RJEČNIK
            <Text as="p">
              Razlikujemo receptivni rječnik - riječi koje razumijemo, i
              ekspresivni rječnik - riječi koje proizvodimo. Veličina
              receptivnog rječnika uvijek je veća od ekspresivnog. Riječi se
              usvajaju spontano, kroz svakodnevne razgovore, pjesmice, čitanje
              priča itd.{' '}
              <b>
                Da bi dijete naučilo neku novu riječ, ono mora biti izloženo toj
                riječi u govoru oko 7 puta, a u pisanom tekstu oko 12 puta.
              </b>
            </Text>
          </li>
          <li>
            PRIPOVIJEDANJE
            <Text as="p">
              Pripovijedanje se odnosi na predstavljanje nekog sadržaja,
              primjerice dijete prepričava ocu što mu se danas dogodilo u
              vrtiću. Ovo je vrlo zahtjevna vještina kojom dijete treba ovladati
              jer dijete i druga osoba, u ovom slučaju otac, nisu zajedno
              sudjelovali u tom događaju.{' '}
              <b>
                Dijete mora dobro vladati jezičnim sredstvima, primjerice
                rječnikom i gramatikom, da jasno prenese informacije o nekom
                događaju o kojem slušatelj ne zna ništa.
              </b>{' '}
              Vrijeme, mjesto, sudionici, uzroci i posljedice moraju biti jasno
              predstavljeni da budu razumljivi slušatelju. Dijete mora nizati
              događaje točno određenim redoslijedom, a istodobno obraćati pažnju
              na slušatelja koji može postavljati potpitanja kojima se dijete
              treba prilagoditi.
            </Text>
          </li>
        </ul>
      </Text>
      <BlogImage
        imgSrc={talkAboutEvent}
        imgAlt="Dijete pripovijeda o nekom dogadaju"
        imgTitle="Dijete pripovijeda o nekom dogadaju"
      />
      <Text as="p">
        <ul>
          <li>
            IMENOVANJE SLOVA
            <Text as="p">
              Imenovanje slova, uz fonološku svjesnost, jedan je od najvažnijih
              pokazatelja kasnije uspješnosti u čitanju. Ovo znanje trebalo bi
              poticati usporedno s poticanjem fonološke svjesnosti, a može joj i
              prethoditi. Ključno je da djeca postanu svjesna da slova
              predstavljaju glasove, i obrnuto. To je važno za kasnije čitanje i
              upamćivanje riječi. Uobičajeno je da djeca lakše prepoznaju
              univerzalna slova, poput <i>p, v</i> ili <i>a</i>, a da{' '}
              <b>
                teže prepoznaju prototipna slova hrvatske latinice koja su nešto
                složenija
              </b>
              , poput <i>š, č, dž</i> ili <i>lj</i>. Također, više poznaju
              velika nego mala tiskana slova.
            </Text>
          </li>
          <li>
            SVJESNOST O TISKU
            <Text as="p">
              Svjesnost o tisku uključuje interes za tiskani materijal,
              primjerice za knjige ili slikovnice, razumijevanje da knjige imaju
              prvu i zadnju stranicu, da se mogu listati, da su stranice
              označene brojevima itd. Zajedničko čitanje slikovnica povećava
              djetetovo shvaćanje važnosti pisanog teksta, ali potrebno je{' '}
              <b>aktivno sudjelovanje djeteta!</b> To znači da bi se s djetetom
              trebalo razgovarati o pročitanom, postavljati mu pitanja,
              primjerice tko je tko ili gdje je što, gdje stoji naslov, odakle
              počinjemo čitati itd.
            </Text>
          </li>
        </ul>
      </Text>
      <Text as="h2">Okolina i predvještine čitanja i pisanja</Text>
      <Text as="p">
        Djeca su od rane dobi zainteresirana za istraživanje svijeta koji ih
        okružuje. Ako su okružena knjigama one će ih privući i htjet će znati
        nešto više o njima.{' '}
        <b>
          Važno je da roditelji ili skrbnici osiguraju okolinu koja će biti
          poticajna za razvoj pismenosti.
        </b>{' '}
        Djeci trebaju biti dostupne dječje knjige, slikovnice, papiri za
        šaranje, bojice i flomasteri. Kada djeci čitamo, trebamo ih uključiti u
        sam proces čitanja, dopustiti im da zajedno s nama prstićem prate
        napredak, pokazati gdje se nalazi naslov, objasniti što znače brojevi na
        stranicama, razgovarati o likovima i radnji, pojasniti nove riječi,
        povezivati sadržaj iz knjige sa svakodnevnim životom, glumiti likove iz
        priče itd. Sve će to olakšati učenje čitanja i pisanja u prvim razredima
        osnovne škole. Dijete koje je zainteresirano za čitanje i pisanje puno
        će lakše napredovati u akademskom okruženju.
      </Text>
      <Text as="p">
        Razvoj pismenosti sastoji se od puno malih koraka kojima dijete treba
        postupno ovladati. Ako dijete ima teškoća u čitanju i pisanju tijekom
        prvog razreda osnovne škole, moguće je da nije ovladalo nekom od
        predvještina čitanja i pisanja. Najčešće se to odnosi na slogovnu ili
        fonemsku analizu i sintezu, koju bi trebalo svladati pa tek onda krenuti
        na složenije zadatke. Napredak od jednostavnijih do složenijih vještina
        omogućava uredan razvoj i osigurava da dijete bez frustracije počne
        otkrivati čudesni svijet knjiga.
      </Text>
      <Text as="p">Krešimir Perša, mag. logoped.</Text>
      <BlogLiterature>
        <ul>
          <li>
            Ivšac Pavliša, J. i Lenček, M. (2011). Fonološke vještine i
            fonološko pamćenje: neke razlike između djece urednoga jezičnoga
            razvoja, djece s perinatalnim oštećenjem mozga i djece s posebnim
            jezičnim teškoćama kao temeljni prediktor čitanja.{' '}
            <i>Hrvatska revija za rehabilitacijska istraživanja</i>, 47(1),
            1-16.
          </li>
          <li>
            Lenček, M. i Užarević, M. (2012). Rana pismenost - vrijednost
            procjene, <i>Hrvatska revija za rehabilitacijska istraživanja</i>,
            52(2), 42-59.
          </li>
        </ul>
      </BlogLiterature>
      <SectionBlog />
    </PageNew>
  );
};

export default PrerequisitesOfReadingAndWriting;
